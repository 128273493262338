type JSONViewerProps = {
    data: any;
  };
  
  const JSONViewer: React.FC<JSONViewerProps> = ({ data }) => {
    return (
      <pre>
        {JSON.stringify(data, null, 2)}
      </pre>
    );
  };
  
  export default JSONViewer;
  