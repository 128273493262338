import React, { useState } from "react";
import JSONViewer from "./JSONViewer";
import "./App.css";
import {
  EconiaTrade,
  RegisteredMarket,
  ENVIRONMENT,
  EconiaMarkets,
  TradeTable,
  BUY,
  SELL,
  eventId,
  subscribeEvents,
} from "@kanalabs/trade";
import { AptosClient } from "aptos";

const client = new AptosClient("https://aptos-testnet.nodereal.io/v1/f1a69a71a9e6449cb21b6470c05a5110/v1/");
const econia = new EconiaTrade(client);

type FunctionItem = {
  name: string;
  execute: () => Promise<any>;
};

function App() {
  const [registeredMarkets, setRegisteredMarkets] = useState<
    RegisteredMarket[]
  >([]);
  const [market, setmarket] = useState<EconiaMarkets>();

  const [response, setResponse] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [userInput, setUserInput] = useState<string>(""); // New state for user input
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value);
  };

  const functionsList: FunctionItem[] = [
    {
      name: "Get Register Market Account",
      execute: async () => {
        const registeredMarkets = await econia.fetchRegisteredMarkets();
        setRegisteredMarkets(registeredMarkets); // Update the state
        return registeredMarkets as any;
      },
    },
    {
      name: "Getting Available Markets",
      execute: async () => {
        const availableMarkets = await econia.getAvailableMarkets(
          registeredMarkets
        );
        return availableMarkets as any;
      },
    },
    {
      name: "Market Instance",
      execute: async () => {
        const marketData = await econia.markets(
          registeredMarkets.filter((market) => market.marketId === 3)[0],
          ENVIRONMENT.TESTNET
        );
        setmarket(marketData);
        return marketData as any;
      },
    },
    {
      name: "Trade Pair Info",
      execute: async () => {
        if (market === undefined) throw new Error("market not initialised");;
        const tradePairInfo = await market.getTradePairInfo();
        return tradePairInfo as any;
      },
    },
    {
      name: "Trade History",
      execute: async () => {
        if (market === undefined) throw new Error("market not initialised");;
        const getAllTrades = (await market.getAllTrades(false)) as TradeTable[];
        return getAllTrades as any;
      },
    },
    {
      name: "Order Book",
      execute: async () => {
        if (market === undefined) throw new Error("market not initialised");;
        const getOrderBook = await market.getOrderBook(true);
        return getOrderBook as any;
      },
    },
    {
      name: "Trade Volume",
      execute: async () => {
        if (market === undefined) throw new Error("market not initialised");;
        const tradeVolume = await market.getTradeVolume();
        return tradeVolume as any;
      },
    },

    {
      name: "Market Price",
      execute: async () => {
        if (market === undefined) throw new Error("market not initialised");;
        const marketPrice_ = await market.marketPrice(true);
        return marketPrice_ as any;
      },
    },
    {
      name: "Deposit Base Coin",
      execute: async () => {
        if (market === undefined) throw new Error("market not initialised");;
        const payload = market.depositBaseCoinPayload("100000");
        return payload as any;
      },
    },
    {
      name: "Deposit Quote Coin",
      execute: async () => {
        if (market === undefined) throw new Error("market not initialised");;
        const payload = market.depositQuoteCoinPayload("100000");
        return payload as any;
      },
    },
    {
      name: "withdraw Base Coin",
      execute: async () => {
        if (market === undefined) throw new Error("market not initialised");;
        const payload = market.withdrawBaseCoinPayload("100000");
        return payload as any;
      },
    },
    {
      name: "withdraw quote Coin",
      execute: async () => {
        if (market === undefined) throw new Error("market not initialised");;
        const payload = market.withdrawQuoteCoinPayload("100000");
        return payload as any;
      },
    },

    {
      name: "User Market Account Info",
      execute: async () => {
        if (market === undefined) throw new Error("market not initialised");;
        const marketInfo = await market.getUserMarketAccount(userInput);
        return marketInfo as any;
      },
    },
    {
      name: "Market Buy Order",
      execute: async () => {
        if (market === undefined) throw new Error("market not initialised");;
        await market.getEstimatedPrice(1, BUY);
        const payload = await market.placeMarketOrder(BUY);
        return payload as any;
      },
    },

    {
      name: "Limit Sell Order",
      execute: async () => {
        if (market === undefined) throw new Error("market not initialised");;
        const sellOrderPayload = await market.placeLimitOrder(SELL, 1, 5.6);
        return sellOrderPayload as any;
      },
    },

    {
      name: "User Order History",
      execute: async () => {
        if (market === undefined) throw new Error("market not initialised");;
        const openOrders = await market.getOpenOrders(userInput, "all");
        return openOrders as any;
      },
    },
    {
      name: "Cancel Order",
      execute: async () => {
        if (market === undefined) throw new Error("market not initialised");;
        const openOrders = await market.getOpenOrders(userInput, "open");
        const cancel = openOrders.bids[0];
        const closeOrder = market.cancelOrder(
          BUY,
          cancel.marketOrderId.toBigInt().toString()
        );
        return closeOrder as any;
      },
    },
    {
      name: "order event",
      execute: async () => {
        if (market === undefined) throw new Error("market not initialised");
        subscribeEvents.on(eventId.orderBook, (data) => {
          console.log('orders', data);
          return data
        });
        market.subscribeToOrderBook(2500);

      },
    },
  ];

  const handleFunctionExecution = async (func: FunctionItem) => {
    setLoading(true);
    setError(null);
    try {
      const result = await func.execute();
      setResponse(result);
    } catch (err) {
      setError(`Error executing function: ${err}`);
    }
    setLoading(false);
  };

  return (
    <div className="App">
      <div className="user-input-section">
        <label>Enter Account Address:</label>
        <input
          type="text"
          placeholder="Enter account address"
          value={userInput}
          onChange={handleInputChange}
        />
      </div>
      <div className="function-list">
        {functionsList.map((func, index) => (
          <button
            key={index}
            onClick={() => handleFunctionExecution(func)}
            disabled={!userInput} // Disable the button if userInput is empty
          >
            {func.name}
          </button>
        ))}
      </div>
      <div className="response-section">
        <h2>Response:</h2>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p style={{ color: "red" }}>{error}</p>
        ) : (
          <JSONViewer data={response} />
        )}
      </div>
    </div>
  );
}

export default App;
